import React from 'react'
import Redirect from '../components/redirect'

// We direct users here when we need to force them to update their client.
const UpdateRedirect = () => (
  <Redirect
    url={() => {
      return 'https://apps.apple.com/app/id1439964290'
    }}
  />
)

export default UpdateRedirect
